program sinvertlog;

{$MODE OBJFPC}
{$MODESWITCH EXTERNALCLASS}

uses
  JS,
  Web,
  Math,
  ChartJS, sinvertlogws; //, sysutils;

function moment(const date: JSValue; const fmt: string): TJSDate; external name 'moment';

function randomNumber(const min, max: Double): Double;
begin
  Result := Random * (max - min) + min;
end;

//function randomBar(const date: TJSDate; const lastClose: Double): TChartTYData;
//var
//  open, close: Double;
//begin
//  open := randomNumber(lastClose * 0.95, lastClose * 1.05);
//  close := randomNumber(open * 0.95, open * 1.05);
//  Result := TChartTYData.new(date.valueOf, close);
//end;
//
function newBar(const date: TJSDate; const value: Double): TChartTYData;
begin
  Result := TChartTYData.new(date.valueOf, value);
end;
//
//procedure generateData(const date, data, labels); assembler;
//asm
//  while (data.length < 60) {
//    date = date.clone().add(1, 'd');
//    if (date.isoWeekday() <= 5) {
//      data.push(pas.program.randomBar(date, data[data.length - 1].y));
//      labels.push(date);
//    }
//  }
//end;

function hexInt(const str): integer; assembler;
asm
    str = '0x' + str;
    return parseInt(str);
end;

function hexFloat(const str): double; assembler;
asm
    str = '0x' + str;
    var sign = (str & 0x80000000) ? -1 : 1;
    var exponent = ((str >> 23) & 0xff) - 127;
    var mantissa = 1 + ((str & 0x7fffff) / 0x7fffff);
    return (sign * mantissa * Math.pow(2, exponent)).toFixed(2);
end;

const
  //dateFormat = 'MMMM DD YYYY';
  dateFormat = 'DD.MM.YYYY hh:mm:ss';
  inv0name = 'Dom';
  inv1name = 'Szopa';
  inv0sn = 'LBC906291010767';
  inv1sn = 'LBCO06291011478';
var
  SinvertLogWebSocket: TSinvertLogWebSocket;
  xhr:TJSXMLHttpRequest;
  select: TJSHTMLSelectElement;
  button: TJSHTMLButtonElement;
  datepicker: TJSHTMLInputElement;
  selectrange: TJSHTMLSelectElement;
  lblenergy: TJSHTMLInputElement;
  lblenergy0: TJSHTMLInputElement;
  lblenergy1: TJSHTMLInputElement;
  chart: TChart;
  config: TChartConfiguration;
  datasetP0: TChartBarDataset; //TChartLineDataset;
  datasetP1: TChartBarDataset; //TChartLineDataset;
  datasetQ0: TChartBarDataset;
  datasetQ1: TChartBarDataset;
  datasetU0: TChartLineDataset;
  datasetU1: TChartLineDataset;
  data, labels: TJSArray;
  date: TJSDate;
  axisT: TChartScaleCartesianTime;
  axisU: TChartScaleCartesian;
  axisQ, axisP: TChartScaleCartesian;
  ticksU, ticksQ: TChartScaleCartesianLinearTick;
  ChartMomentDisplayFormats: TChartMomentDisplayFormats;
  function onDataLoad(Event: TEventListenerEvent): boolean;

  var
    devId: integer;
    i: integer;
    x: array[0..1] of integer;
    Q0: array[0..1] of integer;
    LastQ: array[0..1] of integer;
    P, Q, U: array[0..1] of integer;
    JA, JA2: TJSArray;
    J, J2 : TJSObject;
    valjs: integer;
    //valjsF: double;
    devtime, lastdevtime: JSValue;
    //newlabels : TJSArray;
    //datasetP1: TChartLineDataset; //TChartBarDataset;
    //index: NativeUInt;

    //N,TB : TJSElement;
  procedure addData(aTime: JSValue);
  begin
    writeln(lastdevtime, ' Id:', devid, ' P0:', P[0], '/', x[0], ' P1:', P[1], '/', x[1], ' Q0:', Q[0], '/', x[0], ' Q1:', Q[1], '/', x[1], ' LastQ0:', LastQ[0], ' LastQ1:', LastQ[1]);
    if x[0] > 1 then P[0]:= P[0] div x[0];
    if x[1] > 1 then P[1]:= P[1] div x[1];
    if x[0] > 1 then U[0]:= U[0] div x[0];
    if x[1] > 1 then U[1]:= U[1] div x[1];
    datasetP0.data_.push(newBar(moment(aTime, dateFormat), P[0]));
    datasetP1.data_.push(newBar(moment(aTime, dateFormat), P[1]));
    datasetQ0.data_.push(newBar(moment(aTime, dateFormat), (Q[0] - LastQ[0])/10));
    datasetQ1.data_.push(newBar(moment(aTime, dateFormat), (Q[1] - LastQ[1])/10));
    datasetU0.data_.push(newBar(moment(aTime, dateFormat), U[0]/10));
    datasetU1.data_.push(newBar(moment(aTime, dateFormat), U[1]/10));
    //datasetU1.data_.push(newBar(moment(aTime, dateFormat), U[1]/100));
    //if selectrange.value = 'day' then
    begin
      if Q[0]>0 then LastQ[0]:= Q[0];
      if Q[1]>0 then LastQ[1]:= Q[1];
    end;
    x:= [0, 0];
    P:= [0, 0];
    U:= [0, 0];
  end;

  begin
    if (xhr.status = 200) then
      begin
      writeln('dane załadowane');

      //config.data.labels_.Length:= 0;

      //newDataset := TChartLineDataset.new; //TChartBarDataset.new;
      //newDataset.label_ := 'Inverter data';
      //newDataset.backgroundColor := 'rgba(255, 99, 132, 0.5)';
      //newDataset.borderColor := 'rgb(255, 99, 132)';
      //newDataset.data_ := TJSArray.new;
     ///labels := TJSArray.new;
     ///data := TJSArray.new;
     labels.Length:=0;
     datasetP0.data_.Length:=0;
     datasetP1.data_.Length:=0;
     datasetQ0.data_.Length:=0;
     datasetQ1.data_.Length:=0;
     datasetU0.data_.Length:=0;
     datasetU1.data_.Length:=0;

     Q0:= [0, 0];
     LastQ:= [0, 0];
     P:= [0, 0];
     Q:= [0, 0];
     U:= [0, 0];
     x:= [0, 0];
     lastdevtime:= 0;
      //J:=TJSJSON.parseObject(xhr.responseText);
      JA:=TJSArray(TJSJSON.parse(xhr.responseText));
      for i:=0 to Pred(JA.Length) do
      begin
        J:= TJSObject(JA[i]);
      //*  JA2:= TJSArray(J.Properties['devdata']);

        case selectrange.value of
          'year' :
            begin
              devtime:= copy(string(J.Properties['devtime']),0 , 11);// + '12:00:00';
              axisT.time.unit_ := 'month';
            end;
          'month':
            begin
              devtime:= copy(string(J.Properties['devtime']),0 , 14);// + '00:00';
              axisT.time.unit_ := 'day';
            end
        else
          devtime:= J.Properties['devtime'];
          axisT.time.unit_ := 'hour';
        end;
        config.options.scales.xAxes := [axisT];

        if i=0 then lastdevtime:= devtime;
        if devtime <> lastdevtime then addData(lastdevtime);

        case string(J.Properties['devsn']) of
          inv0sn: devId:= 0;
          inv1sn: devId:= 1;
        else
          devId:= 2;
        end;

        inc(x[devId]);

      //*  J2:= TJSObject(JA2[0]);
        //writeln(J.Properties['devtime'], ' ', J.Properties['devsn'], ' ', devId, ' ', hexFloat(J2.Properties['value']));
      //*  valjs:= round(hexFloat(J2.Properties['value']) * 10) ;
        valjs:= integer(J.Properties['ac_p']);
        P[devId]+= valjs ;

      //*  J2:= TJSObject(JA2[11]);
      //*  valjs:= hexInt(J2.Properties['value']);
        //writeln(J.Properties['devtime'], ' ', J2.Properties['value'], ' ', valjs/10);
        valjs:= integer(J.Properties['q_day']);
      //*  if Q0[devId] = 0 then  Q0[devId]:= valjs;
      //*    Q[devId]:= (valjs-Q0[devId]);
       if selectrange.value = 'day' then
         Q[devId]:= valjs
       else
         Q[devId]+= valjs;

      //*  J2:= TJSObject(JA2[1]);
        //writeln(J.Properties['devtime'], ' ', J.Properties['devsn'], ' ', devId, ' ', hexFloat(J2.Properties['value']));
      //*  valjs:= round(hexFloat(J2.Properties['value']) * 100) ;
        valjs:= integer(J.Properties['ac_u']);
        U[devId]+= valjs ;

        lastdevtime:= devtime;
        if i = Pred(JA.Length) then addData(lastdevtime);
        //labels.push(moment(J.Properties['devtime'], dateFormat));
      end;

      lblenergy.value:= string(JSValue((Q[0] + Q[1])/10)) + ' kWh';
      lblenergy0.value:= string(JSValue(Q[0]/10)) + ' kWh';
      lblenergy1.value:= string(JSValue(Q[1]/10)) + ' kWh';

      //if i > 0 then addData(lastdevtime);

      select := TJSHTMLSelectElement(document.getElementById('type'));

       //datasetP1 := TChartLineDataset.new;
       //datasetP1.label_ := 'Inverter data';
       //datasetP1.backgroundColor := 'rgba(255, 99, 132, 0.5)';
       //datasetP1.borderColor := 'rgb(255, 99, 132)';
       //datasetP1.data_ := data;
   //    datasetP0.type_ := select.value; //'line';
   //    datasetP1.type_ := select.value; //'line';
       //datasetP1.pointRadius := 0;
       //datasetP1.fill := False;
       //datasetP1.lineTension := 0;
       //datasetP1.borderWidth := 2;
       ////config.data.datasets_.pop;
       //config.data.datasets_.push(datasetP1);
       //axisT.ticks.source := 'labels';

      chart.update;
      end
    else
      begin
        writeln('nie można załadować danych');
      end;
  end;

begin
//  date := moment('April 01 2017', dateFormat);
  //date := moment('01.11.2021 12:00:00', dateFormat);
  //data := TJSArray.new(randomBar(date, 30));
  date:= TJSDate.New;
  labels := TJSArray.new(date);


  //generateData(date, data, labels);

  datasetQ0 := TChartBarDataset.new;
  datasetQ0.label_ := 'Q (kWh) - ' + inv0name;
  datasetQ0.yAxisID:= 'yEnergy';
  //datasetQ0.data := [50, 50, 50, 50];
  datasetQ0.type_ := 'bar';
  //datasetQ0.fill := False;
  datasetQ0.borderColor := 'rgb(255, 159, 64)';
  datasetQ0.borderWidth := 1;

  datasetQ1 := TChartBarDataset.new;
  datasetQ1.label_ := 'Q (kWh) - ' + inv1name;
  datasetQ1.yAxisID:= 'yEnergy';
  //datasetQ1.data := [50, 50, 50, 50];
  datasetQ1.type_ := 'bar';
  //datasetQ1.fill := False;
  datasetQ1.borderColor := 'rgb(128, 192, 192)';
  datasetQ1.borderWidth := 1;

  datasetP0 := TChartBarDataset.new;
  //datasetP0 := TChartLineDataset.new;
  datasetP0.label_ := 'P (W) - ' + inv0name;
  datasetP0.backgroundColor := 'rgba(255, 99, 132, 0.5)';
  datasetP0.borderColor := 'rgb(255, 99, 132)';
  //datasetP0.data_ := data;
  datasetP0.type_ := 'bar';
  //datasetP0.type_ := 'line';
  datasetP0.yAxisID:='yPower';
  //datasetP0.pointRadius := 1;
  //datasetP0.fill := False;
  //datasetP0.lineTension := 0.1;
  datasetP0.borderWidth := 1;
  datasetP0.hidden:= True;

  datasetP1 := TChartBarDataset.new;
  //datasetP1 := TChartLineDataset.new;
  datasetP1.label_ := 'P (W) - ' + inv1name;
  datasetP1.backgroundColor := 'rgba(54, 162, 235, 0.5)';
  datasetP1.borderColor := 'rgb(54, 162, 235)';
  //datasetP1.data_ := data;
  datasetP1.type_ := 'bar';
  //datasetP1.type_ := 'line';
  datasetP1.yAxisID:='yPower';
  //datasetP1.pointRadius := 1;
  //datasetP1.fill := False;
  //datasetP1.lineTension := 0.1;
  datasetP1.borderWidth := 1;
  datasetP1.hidden:= True;

  datasetU0 := TChartLineDataset.new;
  datasetU0.label_ := 'U (V) - ' + inv0name;
  datasetU0.yAxisID:='yVoltage';
  datasetU0.type_ := 'line';
  datasetU0.fill := False;
  datasetU0.borderColor := 'rgb(255, 192, 192)';
  datasetU0.hidden:= True;

  datasetU1 := TChartLineDataset.new;
  datasetU1.label_ := 'U (V) - ' + inv1name;
  datasetU1.yAxisID:='yVoltage';
  datasetU1.type_ := 'line';
  datasetU1.fill := False;
  datasetU1.borderColor := 'rgb(0, 192, 192)';
  datasetU1.hidden:= True;

  ChartMomentDisplayFormats := TChartMomentDisplayFormats.new;
  ChartMomentDisplayFormats.hour:= 'HH';

  axisT := TChartScaleCartesianTime.new;
  axisT.type_ := 'time'; //'timeseries';
  //axisT.distribution := 'series';
  axisT.ticks := TChartScaleCartesianTimeTick.new;
  //axisT.ticks.source := 'labels';
  axisT.time := TChartMoment.new;
  axisT.time.displayFormats := ChartMomentDisplayFormats;
  axisT.time.unit_ := 'hour';
  axisT.stacked:= true;

  axisQ:= TChartScaleCartesian.new;
  axisQ.type_:= 'linear';
  axisQ.id:='yEnergy';
  //axisQ.position:= 'right';
  axisQ.label_:= 'Q(kWh)';
  axisQ.stacked:= true;

  ticksQ:= TChartScaleCartesianLinearTick.new;
//  ticksQ.beginAtZero:= true;
  ticksQ.min:= 0;
//  ticksQ.label_:= 'U(V)';
  axisQ.ticks:= ticksQ;

  axisP:= TChartScaleCartesian.new;
  axisP.type_:= 'linear';
  axisP.id:='yPower';
  axisP.position:= 'right';
  axisP.label_:= 'P(W)';
  axisP.stacked:= true;

  axisU:= TChartScaleCartesian.new;
  axisU.type_:= 'linear';
  axisU.id:='yVoltage';
  axisU.position:= 'right';
  axisU.label_:= 'U(V)';
  //axisU.hidden:= true;

  ticksU:= TChartScaleCartesianLinearTick.new;
  ticksU.beginAtZero:= false;
  ticksU.min:= 215;
  ticksU.max:= 255;
  ticksU.label_:= 'U(V)';
  axisU.ticks:= ticksU;

  config := TChartConfiguration.new;
  config.type_ := 'bar';
  config.data := TChartData.new;
  config.data.datasets := [datasetQ0, datasetQ1, datasetP0, datasetP1, datasetU0, datasetU1];
  config.options := TChartOptions.new;
  //config.options.plugins.legend.position = 'left';
  config.options.scales := TChartScalesConfiguration.new;
  config.options.scales.xAxes_ := TJSArray.new(axisT);
  config.options.scales.yAxes_ := TJSArray.new(axisQ, axisP, axisU);
  config.options.tooltips := TChartTooltipsConfiguration.new;
  config.options.tooltips.mode := 'index';
  config.options.tooltips.intersect := false;
  //config.options.hover := TChartHover.new;
  //config.options.hover.mode := 'index';
  //config.options.hover.intersect := true;
  chart := TChart.new('myChart', config);

  select := TJSHTMLSelectElement(document.getElementById('type'));
  select.addEventListener('change',
    procedure
    var
      selectBoolVal: Boolean;
    begin
      if select.value = 'True' then
        selectBoolVal:= True
      else
        selectBoolVal:= False;

      axisT.stacked := selectBoolVal;
      axisQ.stacked := selectBoolVal;
      axisP.stacked := selectBoolVal;
      //axisP.stacked := selectBoolVal;
      config.options.scales.xAxes := [axisT];
      config.options.scales.yAxes := [axisU, axisQ, axisP];

      //chart.config.data.datasets[0].type_ := select.value;
      //chart.config.data.datasets[1].type_ := select.value;
      chart.update;
    end);

  button := TJSHTMLButtonElement(document.getElementById('update'));
  button.addEventListener('click',
    procedure
    var
      FDate, params: string;

    begin
      FDate:= datepicker.value;
      params:= 'Year=' + copy(FDate,1,4);
      if selectrange.value <> 'year' then
        params+= '&Month=' + copy(FDate,6,2);
      if selectrange.value = 'day' then
        params+= '&Day=' + copy(FDate,9,2);
      writeln('btn click, parametry wyslane:', params);
      xhr:=TJSXMLHttpRequest.New;
      xhr.addEventListener('load', @OnDataLoad);
      xhr.open('Post', './jsondata2', true);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.setRequestHeader('charset', 'UTF-8');
      xhr.send(params);
    end);

  datepicker := TJSHTMLInputElement(document.getElementById('datadate'));
  datepicker.value:= copy(date.toJSON, 1, 10);
  writeln(date.toJSON);
  datepicker.addEventListener('change',
    procedure
    begin
      button.click;
    end);

  selectrange := TJSHTMLSelectElement(document.getElementById('daterange'));
  selectrange.addEventListener('change',
    procedure
    begin
      button.click;
    end);

  lblenergy := TJSHTMLInputElement(document.getElementById('lblenergy'));
  lblenergy0 := TJSHTMLInputElement(document.getElementById('lblenergy0'));
  lblenergy1 := TJSHTMLInputElement(document.getElementById('lblenergy1'));

  button.click;

  SinvertLogWebSocket:= TSinvertLogWebSocket.Create;
  SinvertLogWebSocket.paragraph0:= TJSHTMLElement(document.getElementById('datainv0'));;
  SinvertLogWebSocket.paragraph1:= TJSHTMLElement(document.getElementById('datainv1'));;
end.
