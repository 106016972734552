unit sinvertlogws;

{$mode ObjFPC}

interface

uses
  Classes, SysUtils, JS, Web, strutils; //, browserconsole;

type

  { TSinvertLogWebSocket }

  TSinvertLogWebSocket = class
  private
     WS : TJSWebSocket;
     procedure DoConnect;
     function DoOpen(Event: TEventListenerEvent): boolean;
     function DoClosed(Event: TEventListenerEvent): boolean;
     function DoIncomingMessage(Event: TEventListenerEvent): boolean;
  public
     paragraph0: TJSHTMLElement;
     paragraph1: TJSHTMLElement;
     constructor Create;
  end;

implementation

{ TSinvertLogWebSocket }

procedure TSinvertLogWebSocket.DoConnect;
var
  URL,aHost : string;
begin
  aHost:=window.location.host;
  aHost:=ExtractWord(1,aHost,[':']);
  URL:='wss://'+aHost+'/ws';
  writeln('Websocket łączę do ' + URL + '.');
  try
    WS:=TJSWebsocket.New(url);
    WS.onmessage:=@DoIncomingMessage;
    WS.onclose:=@DoClosed;
    WS.onopen:=@DoOpen;
  except
    on TJSError do
      writeln('Nie można zestawić połączenia websocket z ' + URL);
  end
end;

function TSinvertLogWebSocket.DoOpen(Event: TEventListenerEvent): boolean;
begin
  writeln('Websocket kanał komunikacyjny otwarty.');
end;

function TSinvertLogWebSocket.DoClosed(Event: TEventListenerEvent): boolean;
begin
  writeln('Websocket kanał komunikacyjny zamknięty.');
end;

function TSinvertLogWebSocket.DoIncomingMessage(Event: TEventListenerEvent
  ): boolean;
var
  Msg: TJSMessageEvent absolute Event;
  JS : TJSObject;
begin
  //writeln('Websocket przychodząca wiadomość.');
  begin
     if isString(Msg.Data) then
     begin
       try
         JS:=TJSJSON.parseObject(String(Msg.Data));
         writeln('dom' + LineEnding + string(JS.Properties['inverter0']));
         if Assigned(paragraph0) then
           paragraph0.innerText:= 'Dom:' + LineEnding + string(JS.Properties['inverter0']);
         writeln('szopa' + LineEnding + string(JS.Properties['inverter1']));
         if Assigned(paragraph1) then
           paragraph1.innerText:= 'Szopa:' + LineEnding + string(JS.Properties['inverter1']);
       except
         writeln('Dane websocket nie są poprawnym Json.');
         writeln(Msg.Data);
       end;
     end else
     begin
        writeln('<<odebrano nieoczekiwane dane>>');
     end;
  end;
end;

constructor TSinvertLogWebSocket.Create;
begin
  DoConnect;
end;

end.

